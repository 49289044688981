.close-icon path {
  transition:
    stroke 200ms,
    fill 200ms;
}

.close-icon:hover path:not(:first-child) {
  stroke: #ffffff;
}

.close-icon:hover path:first-child {
  fill: #555555;
}

[data-focus-visible-added].tag-input:focus + label {
  @apply outline-default;
}
