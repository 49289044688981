.icon-add-to-list:before {
  content: '\e900';
}
.icon-added-highlight:before {
  content: '\e901';
}
.icon-added-to-list:before {
  content: '\e902';
}
.icon-airplay:before {
  content: '\e903';
}
.icon-alert-circle:before {
  content: '\e904';
}
.icon-alert-triangle-filled:before {
  content: '\e905';
}
.icon-alert-triangle:before {
  content: '\e906';
}
.icon-align-center:before {
  content: '\e907';
}
.icon-align-justify:before {
  content: '\e908';
}
.icon-align-left:before {
  content: '\e909';
}
.icon-align-right:before {
  content: '\e90a';
}
.icon-arrow-down-circle:before {
  content: '\e90b';
}
.icon-arrow-down:before {
  content: '\e90c';
}
.icon-arrow-left-circle:before {
  content: '\e90d';
}
.icon-arrow-left-long:before {
  content: '\e90e';
}
.icon-arrow-left:before {
  content: '\e90f';
}
.icon-arrow-right-circle:before {
  content: '\e910';
}
.icon-arrow-right-long:before {
  content: '\e911';
}
.icon-arrow-right:before {
  content: '\e912';
}
.icon-arrow-up-circle:before {
  content: '\e913';
}
.icon-arrow-up:before {
  content: '\e914';
}
.icon-avatar-alt:before {
  content: '\e915';
}
.icon-award:before {
  content: '\e916';
}
.icon-ban:before {
  content: '\e917';
}
.icon-bar-chart-increase:before {
  content: '\e918';
}
.icon-bar-chart:before {
  content: '\e919';
}
.icon-book:before {
  content: '\e91a';
}
.icon-bookmark:before {
  content: '\e91b';
}
.icon-calendar:before {
  content: '\e91c';
}
.icon-camera:before {
  content: '\e91d';
}
.icon-cast:before {
  content: '\e91e';
}
.icon-check-circle-filled:before {
  content: '\e91f';
}
.icon-check-circle:before {
  content: '\e920';
}
.icon-check-square:before {
  content: '\e921';
}
.icon-check:before {
  content: '\e922';
}
.icon-chevron-down:before {
  content: '\e923';
}
.icon-chevron-left:before {
  content: '\e924';
}
.icon-chevron-right:before {
  content: '\e925';
}
.icon-chevron-up:before {
  content: '\e926';
}
.icon-chevrons-down:before {
  content: '\e927';
}
.icon-chevrons-left:before {
  content: '\e928';
}
.icon-chevrons-right:before {
  content: '\e929';
}
.icon-chevrons-up:before {
  content: '\e92a';
}
.icon-circle:before {
  content: '\e92b';
}
.icon-clock:before {
  content: '\e92c';
}
.icon-copy:before {
  content: '\e92d';
}
.icon-delete:before {
  content: '\e92e';
}
.icon-discussions-overlap:before {
  content: '\e92f';
}
.icon-discussions:before {
  content: '\e930';
}
.icon-download-complete:before {
  content: '\e931';
}
.icon-download:before {
  content: '\e932';
}
.icon-edit-with-pad:before {
  content: '\e933';
}
.icon-edit:before {
  content: '\e934';
}
.icon-endorse-filled:before {
  content: '\e935';
}
.icon-endorse:before {
  content: '\e936';
}
.icon-external-link:before {
  content: '\e937';
}
.icon-eye-off:before {
  content: '\e938';
}
.icon-eye:before {
  content: '\e939';
}
.icon-facebook-messenger-filled:before {
  content: '\e93a';
}
.icon-facebook:before {
  content: '\e93b';
}
.icon-fast-forward-filled:before {
  content: '\e93c';
}
.icon-fast-forward:before {
  content: '\e93d';
}
.icon-file-text:before {
  content: '\e93e';
}
.icon-filter:before {
  content: '\e93f';
}
.icon-filter-3:before {
  content: '\e9a4';
}
.icon-flag:before {
  content: '\e940';
}
.icon-follow:before {
  content: '\e941';
}
.icon-following:before {
  content: '\e942';
}
.icon-globe:before {
  content: '\e943';
}
.icon-headphones:before {
  content: '\e944';
}
.icon-heart-filled:before {
  content: '\e945';
}
.icon-heart:before {
  content: '\e946';
}
.icon-help-circle:before {
  content: '\e947';
}
.icon-home:before {
  content: '\e948';
}
.icon-image:before {
  content: '\e949';
}
.icon-info-circle:before {
  content: '\e94a';
}
.icon-instagram-circle-filled:before {
  content: '\e94b';
}
.icon-instagram:before {
  content: '\e94c';
}
.icon-lightbulb:before {
  content: '\e94d';
}
.icon-link-2:before {
  content: '\e94e';
}
.icon-link-circle-filled:before {
  content: '\e94f';
}
.icon-link:before {
  content: '\e950';
}
.icon-linkedin-circle-filled:before {
  content: '\e951';
}
.icon-linkedin:before {
  content: '\e952';
}
.icon-live:before {
  content: '\e953';
}
.icon-loader:before {
  content: '\e954';
}
.icon-lock:before {
  content: '\e955';
}
.icon-mail-circle-filled:before {
  content: '\e956';
}
.icon-mail:before {
  content: '\e957';
}
.icon-map-pin:before {
  content: '\e958';
}
.icon-maximize:before {
  content: '\e959';
}
.icon-menu:before {
  content: '\e95a';
}
.icon-message-square:before {
  content: '\e95b';
}
.icon-minimize:before {
  content: '\e95c';
}
.icon-minus-circle:before {
  content: '\e95d';
}
.icon-minus:before {
  content: '\e95e';
}
.icon-more-horizontal:before {
  content: '\e95f';
}
.icon-more-vertical:before {
  content: '\e960';
}
.icon-notification-off:before {
  content: '\e961';
}
.icon-notification:before {
  content: '\e962';
}
.icon-pause-circle:before {
  content: '\e963';
}
.icon-pause-filled:before {
  content: '\e964';
}
.icon-pause:before {
  content: '\e965';
}
.icon-paypal-circle-filled:before {
  content: '\e966';
}
.icon-play-circle-filled:before {
  content: '\e967';
}
.icon-play-circle:before {
  content: '\e968';
}
.icon-play-filled:before {
  content: '\e969';
}
.icon-play:before {
  content: '\e96a';
}
.icon-playlist-fill:before {
  content: '\e96b';
}
.icon-playlist-outline:before {
  content: '\e96c';
}
.icon-plus-circle:before {
  content: '\e96d';
}
.icon-plus:before {
  content: '\e96e';
}
.icon-reaction:before {
  content: '\e96f';
}
.icon-recommend-left:before {
  content: '\e970';
}
.icon-recommend-right:before {
  content: '\e971';
}
.icon-remove-highlight:before {
  content: '\e972';
}
.icon-replay:before {
  content: '\e973';
}
.icon-reply:before {
  content: '\e974';
}
.icon-rewind-filled:before {
  content: '\e975';
}
.icon-rewind:before {
  content: '\e976';
}
.icon-save-filled:before {
  content: '\e977';
}
.icon-save-highlight:before {
  content: '\e978';
}
.icon-save:before {
  content: '\e979';
}
.icon-search:before {
  content: '\e97a';
}
.icon-send:before {
  content: '\e97b';
}
.icon-settings-filled:before {
  content: '\e97c';
}
.icon-settings:before {
  content: '\e97d';
}
.icon-share:before {
  content: '\e97e';
}
.icon-skip-back-filled:before {
  content: '\e97f';
}
.icon-skip-back:before {
  content: '\e980';
}
.icon-skip-forward-filled:before {
  content: '\e981';
}
.icon-skip-forward:before {
  content: '\e982';
}
.icon-skip-time-back:before {
  content: '\e983';
}
.icon-skip-time-forward:before {
  content: '\e984';
}
.icon-square:before {
  content: '\e985';
}
.icon-star:before {
  content: '\e986';
}
.icon-stop-circle-filled:before {
  content: '\e987';
}
.icon-stop-circle:before {
  content: '\e988';
}
.icon-subtitles-available:before {
  content: '\e989';
}
.icon-subtitles-unavailable:before {
  content: '\e98a';
}
.icon-tag:before {
  content: '\e98b';
}
.icon-thumbs-down-filled:before {
  content: '\e98c';
}
.icon-thumbs-down:before {
  content: '\e98d';
}
.icon-thumbs-up-filled:before {
  content: '\e98e';
}
.icon-thumbs-up:before {
  content: '\e98f';
}
.icon-trash:before {
  content: '\e990';
}
.icon-trending-up:before {
  content: '\e991';
}
.icon-twitter-circle-filled:before {
  content: '\e992';
}
.icon-twitter:before {
  content: '\e993';
}
.icon-user-minus:before {
  content: '\e994';
}
.icon-user-x:before {
  content: '\e995';
}
.icon-user:before {
  content: '\e996';
}
.icon-video:before {
  content: '\e997';
}
.icon-volume-high-filled:before {
  content: '\e998';
}
.icon-volume-high:before {
  content: '\e999';
}
.icon-volume-low-filled:before {
  content: '\e99a';
}
.icon-volume-low:before {
  content: '\e99b';
}
.icon-volume-mute-filled:before {
  content: '\e99c';
}
.icon-volume-mute:before {
  content: '\e99d';
}
.icon-whatsapp-circle-filled:before {
  content: '\e99e';
}
.icon-x-circle:before {
  content: '\e99f';
}
.icon-x:before {
  content: '\e9a0';
}
.icon-youtube-circle-filled:before {
  content: '\e9a1';
}
.icon-zoom-in:before {
  content: '\e9a2';
}
.icon-zoom-out:before {
  content: '\e9a3';
}
