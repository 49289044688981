/* Video player custom styles */
media-controller [slot='top-chrome'] {
  --media-secondary-color: transparent;
  --media-control-hover-background: rgba(88, 88, 88, 0.85);
}

media-controller [slot='centered-chrome'] {
  border-radius: 50%;
  --media-primary-color: black;

  --media-secondary-color: rgb(255 255 255 / 0.85);
  --media-control-hover-background: rgba(255 255 255 / 0.85);
  --media-control-padding: 24px;
  --media-control-height: 48px;
}

media-time-range {
  --media-primary-color: red;
  --media-secondary-color: transparent;
  --media-control-hover-background: transparent;
  --media-control-padding: 0;
  --media-range-thumb-width: 0;
  width: 100%;
}

media-poster-image {
  --media-object-fit: cover;
}

media-controller[mediahasplayed] [slot='centered-chrome'],
media-controller[mediahasplayed] [slot='poster'],
media-controller:not([mediahasplayed]) media-control-bar {
  display: none;
}

media-controller[mediahasplayed] [slot='middle-chrome'] {
  opacity: 1;
  width: 100%;
  height: 100%;
}

media-controller[breakpointsm] .media-volume-wrapper {
  position: relative;
  padding-left: 6px;
}

media-controller:not([medialoading]) .video-player-loader {
  @apply hidden;
}

media-controller:not([mediavolumelevel])
  #media-mute-button
  span:not([slot='high']):not([slot='icon']),
media-controller[mediavolumelevel='high']
  #media-mute-button
  span:not([slot='high']):not([slot='icon']) {
  display: none !important;
}

media-controller[mediavolumelevel='off']
  #media-mute-button
  span:not([slot='off']):not([slot='icon']) {
  display: none !important;
}

media-controller[mediavolumelevel='low']
  #media-mute-button
  span:not([slot='low']):not([slot='icon']) {
  display: none !important;
}

media-controller[mediavolumelevel='medium']
  #media-mute-button
  span:not([slot='medium']):not([slot='icon']) {
  display: none !important;
}

media-controller media-play-button {
  @apply bg-transparent;
}

/* Hide the cues in normal mode */
video::-webkit-media-text-track-display {
  display: none;
}

/* Show the cues in fullscreen mode on iOS */
.mobile-fullscreen video::-webkit-media-text-track-display {
  display: block;
}

@media (min-aspect-ratio: 2/1) {
  [mediaisfullscreen] #video {
    aspect-ratio: 16 / 7 !important;
  }
}

#media-mute-button:hover + .media-volume-range-wrapper,
#media-mute-button:focus + .media-volume-range-wrapper,
#media-mute-button:focus-within + .media-volume-range-wrapper,
.media-volume-range-wrapper:hover,
.media-volume-range-wrapper:focus,
.media-volume-range-wrapper:focus-within {
  opacity: 1;
}

.media-volume-range-wrapper::before {
  content: '';
  position: absolute;
  border-color: transparent;
  border-style: solid;
  bottom: 50%;
  left: -11px;
  border-width: 8px 8px 0;
  border-top-color: #fff;
  transform: translateY(50%) rotate(90deg);
}
.arrow-dark.media-volume-range-wrapper::before {
  content: '';
  position: absolute;
  border-color: transparent;
  border-style: solid;
  bottom: 50%;
  left: -11px;
  border-width: 8px 8px 0;
  border-top-color: #515151;
  transform: translateY(50%) rotate(90deg);
}

.video-player-range-input::-moz-range-track {
  background: transparent;
}

.video-player-range-input::-webkit-slider-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 100%;
  width: 0.75rem;
}

.video-player-range-input::-ms-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 0;
  appearance: none;
  height: 100%;
  width: 0.75rem;
  background: transparent;
}

.video-player-range-input::-moz-range-thumb {
  border: 0;
  height: 100%;
  width: 0.75rem;
  background: transparent;
}

.vertical-range-input {
  writing-mode: bt-lr;
}

.endcard-title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
