@layer utilities {
  .column-count-auto {
    column-count: auto;
    column-fill: auto;
    column-gap: normal;
  }

  .column-count-2 {
    column-count: 2;
    column-fill: balance;
    column-gap: 16px;
  }

  .column-count-3 {
    column-count: 3;
    column-fill: balance;
    column-gap: 20px;
  }

  .column-count-4 {
    column-count: 4;
    column-fill: balance;
    column-gap: 20px;
  }

  .column-count-6 {
    column-count: 6;
    column-fill: balance;
    column-gap: 20px;
  }
}
