.markdown-content {
  word-break: break-word;
}

.markdown-content em {
  font-style: italic;
}

.markdown-content p {
  margin: 0;
}

.markdown-content a[href] {
  text-decoration: underline;
}

.markdown-content strong {
  font-weight: 700;
}

.markdown-headings h1 {
  font-size: 2em;
  margin-top: 0.67em;
  margin-bottom: 0.67em;
  font-weight: bold;
}

.markdown-headings h2 {
  font-size: 1.5em;
  margin-top: 0.83em;
  margin-bottom: 0.83em;
  font-weight: bold;
}

.markdown-headings h3 {
  font-size: 1.17em;
  margin-top: 1em;
  margin-bottom: 1em;
  font-weight: bold;
}

.markdown-headings h4 {
  font-size: 1em;
  margin-top: 1.33em;
  margin-bottom: 1.33em;
  font-weight: bold;
}

.markdown-headings h5 {
  font-size: 0.83em;
  margin-top: 1.67em;
  margin-bottom: 1.67em;
  font-weight: bold;
}

.markdown-headings h6 {
  font-size: 0.67em;
  margin-top: 2.33em;
  margin-bottom: 2.33em;
  font-weight: bold;
}

.prismic-markdown h1 a[href] {
  text-decoration: none;
}

.prismic-markdown h2 a[href] {
  text-decoration: none;
}

.prismic-markdown h3 a[href] {
  text-decoration: none;
}

.prismic-markdown h4 a[href] {
  text-decoration: none;
}

.prismic-markdown h5 a[href] {
  text-decoration: none;
}

.prismic-markdown h6 a[href] {
  text-decoration: none;
}
