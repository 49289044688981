@keyframes lgyja9sk9d3e0od0pfrw6r79_t {
  0% {
    transform: translate(45px, 45px) rotate(60deg) scale(1, 1);
  }
  17.996% {
    transform: translate(45px, 45px) rotate(189.6deg) scale(1, 1);
  }
  35.993% {
    transform: translate(45px, 45px) rotate(319.1deg) scale(2.3, 1);
  }
  55.969% {
    transform: translate(45px, 45px) rotate(463deg) scale(2.2, 1);
  }
  100% {
    transform: translate(45px, 45px) rotate(780deg) scale(1, 1);
  }
}

@keyframes cnm6x9wdsba8hq466dl9i1ah_o {
  0% {
    opacity: 0.4;
  }
  35.993% {
    opacity: 1;
  }
  100% {
    opacity: 0.4;
  }
}

@keyframes l0n8hi5ae42n5ae0td70lfe9_t {
  0% {
    transform: translate(45px, 45px) rotate(120deg) scale(1, 1);
  }
  17.996% {
    transform: translate(45px, 45px) rotate(249.6deg) scale(1, 1);
  }
  35.993% {
    transform: translate(45px, 45px) rotate(379.1deg) scale(2.3, 1);
  }
  55.969% {
    transform: translate(45px, 45px) rotate(523deg) scale(2.3, 1);
  }
  100% {
    transform: translate(45px, 45px) rotate(840deg) scale(1, 1);
  }
}

@keyframes cmrryy6n5vzvcootss40x797_o {
  0% {
    opacity: 0.4;
  }
  35.993% {
    opacity: 1;
  }
  100% {
    opacity: 0.4;
  }
}
@keyframes u7lccxe4lb9kafiiwa55wwtx_t {
  0% {
    transform: translate(45px, 45px) rotate(0deg) scale(1, 1);
  }
  17.996% {
    transform: translate(45px, 45px) rotate(129.6deg) scale(1, 1);
  }
  35.993% {
    transform: translate(45px, 45px) rotate(259.1deg) scale(2.3, 1);
  }
  55.969% {
    transform: translate(45px, 45px) rotate(403deg) scale(2.3, 1);
  }
  100% {
    transform: translate(45px, 45px) rotate(720deg) scale(1, 1);
  }
}

@keyframes lnkyqqiz5eh16xo1akoqp3i8_o {
  0% {
    opacity: 0.4;
  }
  35.993% {
    opacity: 1;
  }
  100% {
    opacity: 0.4;
  }
}
