[role='tab'][aria-selected='true'] {
  @apply text-black;
}

[role='tab'] .tab-text:after {
  @apply transition-opacity;
  @apply bg-red-500;
  content: '';
  height: 3px;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  opacity: 0;
  z-index: 11;
}

[role='tab'][aria-selected='true'] .tab-text:after {
  opacity: 1;
}

.tabs-overflow-container {
  position: relative;
}

.tabs-overflow-container:after {
  @apply bg-black;
  @apply bg-opacity-16;
  content: '';
  height: 1px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

[role='tab']:only-child .tab-text:after {
  content: none;
}
