/*
 * We had to use flex grid, as IE11 doesn't support CSS grid, which is used in Tailwind's grid system.
 * The 8-col variation was a design choice for certain scenarios on medium and large breakpoints.
 * For example, it is used to make sure there is enough space for 300x250 ads.
 */

@mixin set-column-width($col-count, $breakpoint, $parent-class: '') {
  @for $i from 1 to $col-count by 1 {
    $col-with-gap: calc(100% / $col-count * $i);

    $(parent-class) > .col-$(breakpoint)-$(i) {
      flex-basis: $col-with-gap;
      max-width: $col-with-gap;
    }
  }

  @for $i from 0 to $col-count by 1 {
    /* To exclude col-count */
    @if $i < $col-count {
      $col-with-gap: calc(100% / $col-count * $i);
      $(parent-class) > .col-$(breakpoint)-offset-$i {
        margin-left: $col-with-gap;
      }
    }
  }
}

@include set-column-width(4, sm, .ted-grid);

@screen md {
  @include set-column-width(12, md, .ted-grid);
  @include set-column-width(8, md, .ted-grid-8-col);
}

@screen lg {
  @include set-column-width(12, lg, .ted-grid);
  @include set-column-width(8, lg, .ted-grid-8-col);
}

@screen xl {
  @include set-column-width(12, xl, .ted-grid);
  @include set-column-width(12, xl, .ted-grid-8-col);
}
