/* Copied from nprogress package as it is super short and easy to customize */

/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #eb0028;
  height: 2px;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10000;
}

/* Fancy blur effect */
#nprogress .peg {
  box-shadow:
    0 0 10px #eb0028,
    0 0 5px #eb0028;
  display: block;
  height: 100%;
  opacity: 1;
  position: absolute;
  right: 0px;
  transform: rotate(3deg) translate(0px, -4px);
  width: 100px;
}

#nprogress .peg::after {
  position: absolute;
  display: block;
  content: '';
  bottom: -2px;
  right: -50px;
  width: 200px;
  background: white;
  transform: rotate(-3deg);
  height: 50px;
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  right: 12px;
  bottom: 12px;
  z-index: 10000;
}

#nprogress .spinner-icon {
  animation: nprogress-spinner 400ms linear infinite;
  border: solid 2px transparent;
  border-left-color: #eb0028;
  border-radius: 50%;
  border-top-color: #eb0028;
  box-sizing: border-box;
  height: 16px;
  width: 16px;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
