@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import '../../src/components/@tedui/LoadingAnimation/LoadingAnimation.css';
@import '../../src/components/grid/grid.css';
@import '../../src/components/markdown/markdown.css';
@import '../../src/components/tag/tag.css';
@import '../../src/components/carousel/carousel.css';
@import '../../src/components/filter-dropdown/filter-dropdown.css';
@import '../../src/components/Navigation/css/navigation.css';
@import '../../src/components/search/search.css';
@import '../../src/components/tabs/tabs.css';
@import '../../src/components/PRISMIC/scss/global.css';
@import '../../src/components/video-player/InternalPlayer/player.css';
@import '../../src/components/pages/dashboard/LearningHub/DiscoveryModule/SurpriseMe/slider.css';

@import './nprogress.css';
@import './column-count.css';
@import './scrollbar.css';

@font-face {
  font-family: 'Inter';
  font-weight: 100 900;
  font-style: normal;
  font-named-instance: 'Regular';
  font-display: swap;
  src: url('https://www.ted.com/assets/fonts/inter-upright.var.woff2')
    format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-weight: 100 900;
  font-style: italic;
  font-named-instance: 'Italic';
  font-display: swap;
  src: url('https://www.ted.com/assets/fonts/inter-italic.var.woff2')
    format('woff2');
}

@font-face {
  font-family: 'ted-ui-icons';
  /* TODO: figure out CORS issues in s3 to update these urls to pull from cdn */
  src:
    url('https://www.ted.com/assets/fonts/ted-ui-icons.ttf?ii9i6e')
      format('truetype'),
    url('https://www.ted.com/assets/fonts/ted-ui-icons.woff?ii9i6e')
      format('woff'),
    url('https://www.ted.com/assets/fonts/ted-ui-icons.svg?ii9i6e#ted-ui-icons')
      format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

body {
  font-variant-ligatures: normal;
  touch-action: auto;
}

body::-webkit-scrollbar {
  display: none;
}

html {
  font-family: Inter, sans-serif;
}

html:focus-within {
  scroll-behavior: smooth;
}

:root {
  --reach-dialog: 1;
  --reach-tabs: 1;
}

.lazy-load-image-background {
  line-height: 0;
}

.lazy-load-image-background.opacity {
  background-image: none !important;
  opacity: 0;
}

.lazy-load-image-background.opacity.lazy-load-image-loaded {
  opacity: 1;
  transition: opacity 0.2s;
}

.tippy-content {
  /* TODO: !important bad but it prevents tippy from overwriting */
  padding: 0 !important;
}

/*
Use "outline-inside" class for scenarios when outline needs to be contained
in the parent element (for example cards in carousel, tabs in a tab ribbon...)
*/
.focus-visible.outline-inside:focus {
  outline-offset: -2px;
}

/* use focus-visible polyfill */
[data-js-focus-visible] :focus:not([data-focus-visible-added]) {
  outline: none;
}

.outline-default {
  @apply outline outline-2 outline-offset-2 outline-blue-700;
}

/*
Outline is shown only  when user is using keyboard.
Applying .outline-none class will disable it.
*/
.focus-visible:not(.outline-none):focus {
  @apply outline-default;
}

@layer utilities {
  /* Full height classes with CSS vh unit fix */
  /* https://css-tricks.com/the-trick-to-viewport-units-on-mobile/#css-custom-properties-the-trick-to-correct-sizing */
  .h-100vh {
    height: 100vh; /* Fallback for browsers that do not support CSS variables */
    height: calc(var(--vh) * 100);
  }

  .max-h-100vh {
    max-height: 100vh; /* Fallback for browsers that do not support CSS variables */
    max-height: calc(var(--vh) * 100);
  }
}

/* i element is reserved TED UI icons only  */
i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'ted-ui-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.StripeElement {
  line-height: 1.35;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 12px;
  width: 100%;
  background-color: white;
  height: 2.5rem;
  box-sizing: border-box;
  font-size: 16px;
}

/* Hide the "Powered by OneTrust" logo from the Preference Center */
#onetrust-pc-sdk .ot-pc-footer-logo a {
  display: none !important;
}

.animating-circle {
  clip-path: circle(0%);
  transition: clip-path 0.6s;
  transform: translateZ(0);
  width: calc(100% + 1px);
}

.fancy-button:hover > .animating-circle {
  clip-path: circle(100%);
  transform: translateZ(0);
  width: calc(100% + 1px);
}

.mulp-faq > div[data-state='open'] button {
  @apply rounded-b-none;
}

.mulp-faq > div[data-state='open'] div[role='region'] {
  @apply rounded-t-none;
}

@media screen and (min-width: 576px) and (max-width: 640px) {
  .membership-banner-content {
    width: 40%;
  }
}

/*
  * Swiper pagination bullet opacity fix
  * Swiper v6's default behavior is to take the last active class
  * and append -main, -next, -prev, etc. to it. In order to use
  * dynamic bullets, this is the fix to only show the main bullets
*/
.swiper-pagination-bullet {
  @apply cursor-pointer;
}

.swiper-pagination-bullet:not(.dark) {
  @apply bg-gray-400 !opacity-20;
}

.swiper-pagination-bullet.dark {
  @apply bg-white !opacity-50;
}

.swiper-active-bullet.dark {
  @apply bg-white !opacity-100;
}

.swiper-active-bullet {
  @apply bg-black !opacity-100;
}

.swiper-active-bullet-prismic {
  @apply bg-white;
}

.swiper-active-bullet-main {
  @apply !scale-100;
}

.related-talks-swiper {
  visibility: hidden;
  height: 20px;
}

#cloned-standard-hero-slider-thumbs .swiper-slide-thumb-active #thumb,
#personalized-standard-hero-slider-thumbs .swiper-slide-thumb-active #thumb,
#standard-hero-slider-thumbs .swiper-slide-thumb-active #thumb {
  @apply md-tui:border-t-[5px] md-tui:border-textTertiary-onDark;
}

#cloned-standard-hero-slider-thumbs .swiper-slide-thumb-active .title,
#personalized-standard-hero-slider-thumbs .swiper-slide-thumb-active .title,
#standard-hero-slider-thumbs .swiper-slide-thumb-active .title,
#cloned-standard-hero-slider-thumbs .swiper-slide-thumb-active .topic,
#personalized-standard-hero-slider-thumbs .swiper-slide-thumb-active .topic,
#standard-hero-slider-thumbs .swiper-slide-thumb-active .topic {
  @apply !text-white;
}

#cloned-standard-hero-slider
  .swiper-slide:not(.swiper-slide-thumb-active)
  .title,
#personalized-standard-hero-slider
  .swiper-slide:not(.swiper-slide-thumb-active)
  .title,
#standard-hero-slider-thumbs
  .swiper-slide:not(.swiper-slide-thumb-active)
  .title,
#cloned-standard-hero-slider-thumbs
  .swiper-slide:not(.swiper-slide-thumb-active)
  .topic,
#personalized-standard-hero-slider-thumbs
  .swiper-slide:not(.swiper-slide-thumb-active)
  .topic,
#standard-hero-slider-thumbs
  .swiper-slide:not(.swiper-slide-thumb-active)
  .topic {
  @apply !text-dark-text-foreground;
}

#cloned-standard-hero-slider-pagination .swiper-pagination-bullet,
#personalized-standard-hero-slider-pagination .swiper-pagination-bullet,
#standard-hero-slider-pagination .swiper-pagination-bullet {
  @apply !h-[5px] !w-full !rounded-none bg-dark-text-foreground;
}

#cloned-standard-hero-slider-thumbs .swiper-slide a::after,
#personalized-standard-hero-slider-thumbs .swiper-slide a::after,
#standard-hero-slider-thumbs .swiper-slide a::after {
  @apply absolute right-0 top-1/2 h-3/4 w-px -translate-y-1/2 bg-textTertiary-onLight lg-tui:content-[""];
}

#cloned-standard-hero-slider-thumbs .swiper-slide:last-child a::after,
#personalized-standard-hero-slider-thumbs .swiper-slide:last-child a::after,
#standard-hero-slider-thumbs .swiper-slide:last-child a::after {
  content: none;
}

.scroll-container {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  overscroll-behavior: contain !important;
}

.scroll-container::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
  overscroll-behavior: contain !important;
}

#summary-swiper .swiper-container {
  height: auto !important;
}

.summary-slide {
  height: auto !important;
  align-self: stretch;
  transform: none !important;
}

.arrow-size {
  width: 1.2rem !important;
  height: 1.2rem !important;
}

.arrow-size-down {
  width: 1rem !important;
  height: 1rem !important;
}

.scroll-container {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.scroll-container::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

#summary-swiper .swiper-container {
  height: auto !important;
}
.summary-slide {
  height: auto !important;
  align-self: stretch;
}

.arrow-size {
  width: 1.2rem !important;
  height: 1.2rem !important;
  top: -0.6rem !important;
}

.tippy-arrow-dark.tippy-box[data-theme~='light'][data-placement^='top']
  > .tippy-arrow:before {
  border-top-color: #515151;
}
.tippy-box[data-theme~='light'][data-placement^='bottom']
  > .tippy-arrow:before {
  border-bottom-color: #515151;
}

.scroll-touch {
  -webkit-overflow-scrolling: touch;
}

.h-screen {
  height: 100vh;
  /* Fix for mobile browsers since 100vh doesn't take into account the browser's address bar and dvh/svh/lvh is not fully supported */
  height: calc(var(--vh, 1vh) * 100);
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

.skeleton {
  background: linear-gradient(to right, #f6f7f8 8%, #edeef1 18%, #f6f7f8 33%);
  background-size: 1000px 100%;
  animation: shimmer 2s infinite linear;
}

.dark-svg-icon.dark-svg-icon_stroke path {
  stroke: white;
}

.dark-svg-icon.dark-svg-icon_fill path,
.dark-svg-icon.dark-svg-icon_fill circle {
  fill: white;
}

label.newsletter-input {
  font-weight: normal;
  display: block;
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
  color: #767676;
  transform-origin: left top;
  user-select: none;
  transition: transform 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

input.newsletter-input {
  width: 100%;
  height: 52px;
  font-size: 14px;
}

input.newsletter-input:focus {
  outline: none;
}

input.newsletter-input:focus + label {
  color: #767676;
}

input.newsletter-input:focus + label,
input.is-valid.newsletter-input + label {
  transform: translateY(-100%) scale(0.75);
}

#donut svg {
  @apply overflow-visible;
}

#donut *:focus {
  @apply outline-none;
}

#info-button-talk-page:focus {
  @apply size-[18px] rounded-full py-px pl-[1.5px] pr-px;
}

