/* TODO: Set globally and import where needed */
$col-gap-small: 16px;
$col-gap-large: 20px;

/*
We use "no-hover" helper class to hide carousel arrow buttons on iOS and Android touch devices.
**/
.no-hover .carousel-button {
  display: none;
}

/*
Because we use custom arrow buttons, we need to define disabled state style
**/
.swiper-button-disabled {
  display: none;
}

/*
Because carousel container has it's overflow hidden, we offeset focus outline a little to prevent it being cutting off
**/
.swiper-slide > a.focus-visible:focus {
  outline-offset: -2px;
}

/*
We use this calculation to define the width of the carousel slides so that they align with our grid (per breakpoint).
Done based on the number of visible slides (grid columns) and taking into account spacing between the slides (grid gap).
**/
@mixin slide-width($slides-per-page: 1, $gap: $col-gap-large) {
  width: calc((100% - $gap * ($slides-per-page - 1)) / $slides-per-page);
  margin-right: $gap;
}

.slide--large {
  @include slide-width(1, $col-gap-small);
}

.slide--medium {
  @include slide-width(2, $col-gap-small);
}

.slide--small {
  @include slide-width(2, $col-gap-small);
}

@screen md {
  .slide--large {
    @include slide-width(2, $col-gap-large);
  }

  .slide--medium {
    @include slide-width(3, $col-gap-large);
  }

  .slide--small {
    @include slide-width(4, $col-gap-large);
  }
}

@screen lg {
  .slide--large {
    @include slide-width(3, $col-gap-large);
  }

  .slide--medium {
    @include slide-width(4, $col-gap-large);
  }

  .slide--small {
    @include slide-width(6, $col-gap-large);
  }
}

@screen xl {
  .slide--medium {
    @include slide-width(6, $col-gap-large);
  }
}
