@layer utilities {
  /* Remove default search close button */
  input[type='search']::-webkit-search-cancel-button {
    display: none;
  }

  /* Remove rounded style from safari on iphones */
  input[type='search'] {
    -webkit-appearance: none;
  }
}
