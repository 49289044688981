.table {
  width: 100%;
  border-color: #f6f6f6;
  border-style: solid;
  border-width: 1px 0;
  line-height: 1.3;
  margin-bottom: 2em;
  min-width: 600px;
}

.table__cell {
  border: solid #f6f6f6;
  border-width: 1px 0 0;
  padding: 10px;
  text-align: left;
  vertical-align: top;
}

.table__cell--heading {
  border-color: #666;
  color: #999;
  font-weight: 700;
  text-transform: uppercase;
}

thead .table__cell--heading {
  border-width: 1px 0;
}

.clearfix:after {
  content: '';
  display: table;
  clear: both;
}

.customSection p {
    margin-bottom: 0 !important;
  }
