@layer utilities {
  .no-scrollbar {
    /* Firefox */
    scrollbar-width: none;
    /* IE 10+ */
    -ms-overflow-style: none;
  }

  .no-scrollbar-horizontal {
    /* Firefox */
    scrollbar-width: none;
    /* IE 10+ */
    -ms-overflow-style: none;
    /* We are using these values to assure that native scrollbar will be hidden on iOS devices */
    padding-bottom: 15px;
    margin-bottom: -15px;
  }

  /* WebKit - Safari, Chrome, Opera */
  .no-scrollbar::-webkit-scrollbar,
  .no-scrollbar-horizontal::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}
